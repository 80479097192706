import { useState } from 'react';
import { urls } from '../../../libs/constants';

import Button from '../Button/Button';

const { PAPER_FORM_URL } = urls;

const BookConsultationButton = () => {
    const [isHovering, setHover] = useState(false);
    return (
        <Button
            type="external-link"
            url={PAPER_FORM_URL}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {isHovering ? "Let's get that A+!" : 'Book Consultation'}
        </Button>
    );
};

export default BookConsultationButton;
