import { lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { createInstance } from './redux/analytics/analytics.reducer';
import { getData } from './redux/app/app.reducer';

import PageLoader from './components/loaders/PageLoader/PageLoader';
import Layout from './pages/Layout';

const AboutPage = lazy(() => import('./pages/AboutPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const SupportPage = lazy(() => import('./pages/SupportPage'));
const TermsPage = lazy(() => import('./pages/TermsPage'));
const ThankYouPage = lazy(() => import('./pages/ThankYouPage'));
const TutoringPage = lazy(() => import('./pages/TutoringPage'));
const K6ProgramPage = lazy(() => import('./pages/K6ProgramPage'));

const WorksheetPage = lazy(() =>
    import('./pages/WorksheetPages/WorksheetPage')
);
const BookPage = lazy(() => import('./pages/WorksheetPages/BookPage'));
const MaterialPage = lazy(() => import('./pages/WorksheetPages/MaterialPage'));
const LecturesPage = lazy(() => import('./pages/WorksheetPages/LecturesPage'));
const QuestionPage = lazy(() => import('./pages/WorksheetPages/QuestionPage'));

const BlogPostPage = lazy(() => import('./pages/BlogPages/BlogPostPage'));
const BlogPostDetailsPage = lazy(() =>
    import('./pages/BlogPages/BlogPostDetailsPage')
);

const routes = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,

        children: [
            { index: true, element: <HomePage /> },

            { path: 'about', element: <AboutPage /> },
            { path: 'blog', element: <BlogPostPage /> },
            { path: 'blog/:postId', element: <BlogPostDetailsPage /> },
            { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
            { path: 'support', element: <SupportPage /> },
            { path: 'terms-of-use', element: <TermsPage /> },
            { path: 'thankyou', element: <ThankYouPage /> },
            { path: 'tutoring', element: <TutoringPage /> },
            { path: 'k6program', element: <K6ProgramPage /> },

            { path: 'worksheets', element: <WorksheetPage /> },
            { path: 'worksheets/:bookName', element: <BookPage /> },
            {
                path: 'worksheets/:bookName/:chapterName/:materialName',
                element: <MaterialPage />,
            },
            {
                path: 'worksheets/:bookName/:chapterName/:materialName/lectures/:topicId',
                element: <LecturesPage />,
            },
            {
                path: 'worksheets/:bookName/:chapterName/:materialName/:questionId',
                element: <QuestionPage />,
            },

            { path: 'workbooks', element: <WorksheetPage /> },
            { path: 'workbooks/:bookName', element: <BookPage /> },
            {
                path: 'workbooks/:bookName/:chapterName/:materialName',
                element: <MaterialPage />,
            },
            {
                path: 'workbooks/:bookName/:chapterName/:materialName/lectures/:topicId',
                element: <LecturesPage />,
            },
            {
                path: 'workbooks/:bookName/:chapterName/:materialName/:questionId',
                element: <QuestionPage />,
            },

            //SEO pages with repetitive components
            { path: 'math-tutoring', element: <HomePage /> },
            { path: 'trigonometry-math-tutoring', element: <HomePage /> },
            { path: 'algebra-math-tutoring', element: <HomePage /> },
        ],
    },
    {
        path: '*',
        element: (
            <Suspense fallback={<PageLoader />}>
                <NotFoundPage />
            </Suspense>
        ),
    },
]);

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(createInstance());
        dispatch(getData());
    }, []);

    return <RouterProvider router={routes}></RouterProvider>;
}

export default App;
